<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignBdoCardForm"
        ref="assignBdoCardForm"
        class="custom-form pt-6"
        @submit.prevent="assign"
      >
        <v-col cols="12">
          <v-data-table
            hide-default-footer
            class="cards-table"
            :headers="headers"
            :items="parsedBdoCards"
            :items-per-page="-1"
            @click:row="selectCard"
          />
        </v-col>

        <embed
          v-if="pdfFileUrl"
          :src="pdfFileUrl"
          type="application/pdf"
          width="100%"
          height="500px"
        >
        <span
          v-else-if="bdoCardId"
          class="error--text ml-4"
        >Brak karty KPO/KPOK do wyświetlenia</span>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        form="assignBdoCardForm"
        :disabled="!bdoCardId"
        :loading="isProcessing"
      >
        Przypisz kartę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import rules from '../../utils/validators'
import { dateStringFormat } from '../../utils'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import DateTime from 'luxon/src/datetime'

const headers = [
  { text: 'Nr KPO', value: 'cardNumber' },
  { text: 'Firma przekazująca', value: 'senderName' },
  { text: 'MPD przekazującego', value: 'senderBusinessPlace.name' },
  { text: 'Adres MPD przekazującego', value: 'senderBusinessPlace.address' },
  { text: 'Wytw. odp.', value: 'wasteGenerating' },
  { text: 'Firma przejmująca', value: 'receiverName' },
  { text: 'MPD przejmującego', value: 'receiverBusinessPlace.name' },
  { text: 'Kod odpadu', value: 'reportedDebrisType.code' },
  { text: 'Data', value: 'date' },
  { text: 'Status karty', value: 'cardStatus' }
]

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      headers,
      bdoCardId: null,
    }
  },
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.bdoCard.isProcessing,
      bdoCards: state => state.bdoCards.items
    }),
    course () {
      return this.item?.swapCourse || this.item
    },
    order () {
      return this.course.order
    },
    pdfFileUrl () {
      return this.bdoCards.find(card => card.id === this.bdoCardId)?.bdoFile?.url
    },
    parsedBdoCards() {
      return this.bdoCards.map((card) => ({
        ...card,
        wasteGenerating: card.wasteGeneratingAdditionalInfo ? 'Tak' : 'Nie',
        date: card.cardStatus === 'Zatwierdzona' ? dateStringFormat(card.realTransportTime) : dateStringFormat(card.plannedTransportTime)
      }))
    }
  },
  created () {
    const DateTimeDueDate = DateTime.fromISO(this.course.dueDate)
    const dateRangeFrom = DateTimeDueDate.minus({ days: 1 }).toISODate()
    const dateRangeTo = DateTimeDueDate.plus({ days: 1 }).toISODate()
    const bdoCardsParams = {
      assigned: false,
      filters: {
        clientId: this.order.client.id,
        debrisTypeId: this.order.debrisType.id,
        cardStatuses: ['Zatwierdzona', 'Potwierdzenie wygenerowane'],
        daterange: [dateRangeFrom, dateRangeTo]
      }
    }
    this.getBdoCards({ params: bdoCardsParams })
  },
  methods: {
    ...mapActions({
      getBdoCards: 'bdoCards/getItems',
      assignOrder: 'bdoCard/assignOrderToBdoCard',
      assignCourse: 'bdoCard/assignCourseToBdoCard',
    }),
    selectCard(card) {
      this.bdoCardId = card.id
    },
    assign () {
      if (this.$refs.assignBdoCardForm.validate()) {
        if (this.course.anyContainer) {
          const params = { courseId: this.course.id, bdoCardId: this.bdoCardId }
          this.assignCourse(params)
            .then(() => { this.afterFormSubmitted('Przypisano kartę') })
        } else {
          const params = { orderId: this.order.id, bdoCardId: this.bdoCardId }
          this.assignOrder(params)
            .then(() => { this.afterFormSubmitted('Przypisano kartę') })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-table::v-deep .v-data-table__wrapper {
  height: 360px;
  overflow-y: auto;
  tr td:last-child {
    padding-right: 8px !important;
  }
}
</style>
